import Select from 'react-select';
import {options} from "./LearningLibraryItemTypes";

export default function LearningLibraryItemTypeSelect({onChange, isMulti=false}) {

    return (
        <Select
            defaultValue={[]}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            placeholder={"Select the type of the content"}
            closeMenuOnSelect={!isMulti}
        />
    )

}
