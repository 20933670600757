import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment'

const options = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month',
                // round: 'month'
            }
        },
        y: {
            // beginAtZero: true,
            stacked: true
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top'
        },
        title: {
            display: true,
            text: 'Growth Over Time',
        },
    },
    aspectRatio: 1.2
};

export const getDateAsMonthOnly = (dateString) => {
    const date = new Date(dateString)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
}

export const getTotal = (response, attribute) => {
    let total = 0
    if (response && response.skillGroups) {
        Object.values(response.skillGroups).forEach(skillGroup => {
            Object.values(skillGroup).forEach(skill => {
                if (skill[attribute]) {
                    total += skill[attribute]
                }
            })
        });
    }
    return total
}

export default function SelfRatingGrowthChart({selfResponses}) {

    const [data,setData] = useState(null);

    useEffect(() => {

        if (selfResponses && selfResponses.length) {
            const dataBeforeParse = {}
            let username = ''

            const todaysDate = new Date()
            const todaysDateString = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate()

            const latestScores = {}

            console.log(selfResponses)

            selfResponses.forEach(response => {
                const dateKey = getDateAsMonthOnly(response.modified)
                username = response.username
                if (dataBeforeParse[dateKey] === undefined) {
                    dataBeforeParse[dateKey] = {}
                }

                if (dataBeforeParse[dateKey][response.username] === undefined) {

                    const total = getTotal(response, 'selfRating')

                    dataBeforeParse[dateKey][response.username] = {
                        label: response.targetUserGivenName + ' ' + response.targetUserFamilyName,
                        total: total,
                        modified: new Date(response.modified)
                    }

                    latestScores[response.username] = total

                } else if (new Date(response.modified) > dataBeforeParse[dateKey][response.username].modified) {

                    const total = getTotal(response, 'selfRating')

                    dataBeforeParse[dateKey][response.username].total = total
                    dataBeforeParse[dateKey][response.username].modified = new Date(response.modified)

                    latestScores[response.username] = total

                }

            })

            if (dataBeforeParse[todaysDateString] === undefined) {
                dataBeforeParse[todaysDateString] = {}
                Object.entries(latestScores).forEach(([username, total]) => {
                    dataBeforeParse[todaysDateString][username] = {
                        total: total,
                    }
                })
            }
            console.log(dataBeforeParse)

            const dataset = {
                datasets: [
                    {
                        label: 'Self Rating',
                        data: Object.entries(dataBeforeParse).map(([key, object]) => {
                            return {x: key, y: object[username].total}
                        }),
                        fill: true,
                        backgroundColor: 'rgba(91,16,255,0.2)',
                        borderColor: 'rgba(91,16,255,1)',
                        borderWidth: 1,
                    }
                ]
            }
            setData(dataset)

        }

    }, [selfResponses])

    return <Line data={data} options={options}/>

}
