import Button from "@material-ui/core/Button";
import AuthService from "../../services/AuthService";
import React, {useState} from "react";
import {CircularProgress} from "@material-ui/core";
import SkillpacaApi from "../../services/SkillpacaApi";

const authService = AuthService()
const skillpacaApi = SkillpacaApi()

export default function LaunchSurveyButton({surveyId, children}) {

    const [isLoading, setIsLoading] = useState(false)

    async function clickedLaunchSurveyButton(surveyId) {
        setIsLoading(true)
        const newResponse = await skillpacaApi.createOrGetInProgressResponse(surveyId)
        const jwt = await skillpacaApi.getJwtForResponse(newResponse.id)
        const surveyLink = "https://survey.skillpaca.com/survey/" + authService.getCurrentTenant() + "/" + surveyId  + '?jwt=' + jwt
        window.location = surveyLink
    }

    if (isLoading) {
        return <CircularProgress/>
    }

    return (
        <Button  onClick={() => clickedLaunchSurveyButton(surveyId)} variant={"contained"} color={"primary"}>{children}</Button>
    )
}
