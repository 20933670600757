import {Route, Routes, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import FullScreenLoading from "../util/FullScreenLoading";
import MentorList from "./MentorList";
import MentorDetails from "./MentorDetails";
import SkillpacaApi from "../../services/SkillpacaApi";
import AuthService from "../../services/AuthService";

const skillpacaApi = SkillpacaApi()
const authService = AuthService()

const mentors = { practeraEng: [
    {
        name: "Sunil",
        username: "sunil@practera.com",
        email: "sunil@practera.com",
        role: 'Senior DevOps Engineer',
        bio: "<p>I live in Sydney, I've been working with Practera for about a year now.</p><p>I love all things DevOps, setting up CI/CD pipelines, writing cloudformation, architecting infrastructure, playing with servers.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U016R04BE81-829e08f8e331-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "123", "title": "AWS"},{"id": "1234", "title": "CI/CD"},{"id": "1235", "title": "Git"},{"id": "1236", "title": "DynamoDB"},{"id": "1237", "title": "AWS Lambda"},{"id": "1238", "title": "AWS SNS/SQS"},{"id": "2238", "title": "Cloud computing"},{"id": "3238", "title": "DevOps concept"},{"id": "4238", "title": "CI/CD Approach"},{"id": "5238", "title": "Security concept in cloud"}]
    },
    {
        name: "Shawn",
        username: "shawn@practera.com",
        email: "shawn@practera.com",
        role: 'Lead Full Stack Engineer',
        bio: "<p>I’m currently working from China and joined Practera in 2015.</p><p>I worked on many CORE and CUTIE projects and own our GraphQL initiative. I love working across the backend, frontend and DevOps areas as well as architecting software systems and deploying smart solutions that provide value to our users.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U0DFSQLJC-77034529c02d-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "GraphQL APIs"},{"id": "2", "title": "Rest APIs"},{"id": "3", "title": "SQL"},{"id": "4", "title": "Git"},{"id": "5", "title": "HTML"}]
    },
    {
        name: "Terence",
        username: "terence@practera.com",
        email: "terence@practera.com",
        role: 'Senior Backend Engineer',
        bio: "<p>I am working remotely from Malaysia and started working with Practera a long time ago in 2013.</p><p>I love using my expertise in PHP and am highly skilled in our entire backend stack to help create a product that our customers love. Over the years, I’ve been involved in every feature area of our educator interface and would be happy to offer my help to anyone who wants to learn more about it.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U1GJ48N5V-e8d015482bf9-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "Rest APIs"},{"id": "2", "title": "SQL"},{"id": "3", "title": "PHP"},{"id": "4", "title": "jQuery"},{"id": "5", "title": "HTML"}]
    },
    {
        name: "Chaw",
        username: "chaw@practera.com",
        email: "chaw@practera.com",
        role: 'Lead Frontend Engineer',
        bio: "<p>I live in Malaysia and joined Practera in 2016 - more than 5 years ago!</p><p>I love working on our user facing app interfaces and I am skilled in all areas of our frontend stack including all our web development languages, especially Ionic/Angular.</p><p>Over the years, I’ve been involved in all Ionic projects and every feature that touched our learner and expert apps, from the first prototype of our PWA to the current live versions. I’d be happy to share my experience with you, if you want to learn more about it.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U1GBY5XKJ-cf68826a4c44-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "Angular"},{"id": "2", "title": "Vanilla Browser JS"},{"id": "3", "title": "Ionic"},{"id": "4", "title": "jQuery"},{"id": "5", "title": "Git"},{"id": "6", "title": "HTML"}]
    },
    {
        name: "Feyi",
        username: "feyi@practera.com",
        email: "feyi@practera.com",
        role: 'Full Stack Engineer',
        bio: "<p>I’ve recently joined Practera as Backend Engineer and I love architecting and implementing software products and apps.</p><p>In the past, I’ve worked on several apps that were published to app stores, enjoy working with APIs and am skilled at most web development languages.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U02AYBMSKMM-8c2875e62c82-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "Publishing to App stores"},{"id": "9", "title": "React"},{"id": "2", "title": "Rest APIs"},{"id": "3", "title": "Typescript"},{"id": "4", "title": "Git"},{"id": "5", "title": "HTML"},{"id": "6", "title": "SQL"},{"id": "7", "title": "PHP"},{"id": "8", "title": "Vanilla Browser JS"}]
    },
    {
        name: "Richard Watts-Seale",
        username: "richard+praceteraEng@skillpaca.com",
        email: "richard+praceteraEng@skillpaca.com",
        role: 'Full Stack Engineer',
        bio: "<p>I live in Sydney, I've been working with Practera for about a year now.</p><p>I specialise around rapid prototyping and getting ideas out the door. Due to this I've become a bit of a generalist where I am quite familiar with all sides of application development.</p>",
        picture: 'https://ca.slack-edge.com/T02TCHV4Y-U012ZNAMJJG-e0a238508b0c-512',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "React"},{"id": "2", "title": "AWS Lambda"},{"id": "3", "title": "Software Architecture"},{"id": "4", "title": "Rapid Prototyping"}]
    }
],
pageup: [
    {
        name: "Kaitlyn Vu",
        username: "kaitlynv@pageuppeople.com",
        email: "kaitlynv@pageuppeople.com",
        role: 'Senior Experience Designer',
        bio: "<p>I am a UX designer based in Melbourne.</p>" +
            "<p>What I do exactly</p>" +
            "<p>Practice user-centred approach<br>" +
            "I observe and talk to users to understand their pain points so that I can provide them with possible solutions.</p>" +
            "<p>Design the experience<br>" +
            "From the sketchy wireframes to the polished interactive prototype, I make sure the experience is fluid from end-to-end.</p>" +
            "<p>Usability Testing<br>" +
            "The fun part comes when letting people use the prototype and get feedback to iterate the solutions. </p>",
        picture: 'https://pbs.twimg.com/profile_images/882223774061387778/z75HHVsi_400x400.jpg',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "Low and High Fidelity Interaction Design"},{"id": "2", "title": "Usability"}]
    },
    {
        name: "Maor",
        username: "maors@pageuppeople.com",
        email: "maors@pageuppeople.com",
        role: 'Lead User Researcher',
        bio: "<p>I am a 🧔‍♂️ human, 😍 Father, 👨‍🔬 Researcher and a 👨‍🎨 Visual storyteller, Equipped with 15+ years of solving problems and understanding products, humans, and the relationships between the two. I got hands-on experience in mixed methodology research, design, prototyping, and testing and get my energy from unveiling valuable stories.</p>" +
            "<p>I worked in corporate, startups and as a freelancer, which gives me an understanding of various organisational structures and characteristics, and how to get the best results within each of the different environments.</p>" +
            "<p>I'm motivated by people who care about people and who work together to discover and solve the right problem for the right audience.</p>",
        picture: 'https://media-exp1.licdn.com/dms/image/C5603AQGsE7xO_q7i1A/profile-displayphoto-shrink_800_800/0/1580875334998?e=1643241600&v=beta&t=7mYY5behLjBCeD-sH1v1uuen11sL-_VYkEdjd1Mww-A',
        howToReach: "Send me a message on Slack!",
        skills: [{"id": "1", "title": "Plan research activities"},{"id": "2", "title": "Run effective research activities"},{"id": "3", "title": "Analysis and Synthesis"}]
    }
]}


export default function PeerLearningContainer() {
    const {surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState();
    const [responses, setResponses] = useState();
    const [response, setResponse] = useState({});

    useEffect(() => {
        setIsLoading(true)
        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getResponses(surveyId)]).then(([survey, responses]) => {
            setSurvey(survey)
            if (responses[0]) {
                setResponse(responses[0])
            }
        })
        setIsLoading(false)
    }, [surveyId])

    function fetchMentors() {
        return mentors[authService.getCurrentTenant()] ? mentors[authService.getCurrentTenant()] : []
    }

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.peerLearning} response={response}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.peerLearning} response={response}>
            <Routes>
                <Route path="/:username" element={<MentorDetails mentors={fetchMentors()} response={response} />} />
                <Route path="/" element={<MentorList mentors={fetchMentors()} response={response} />} />
            </Routes>
        </ResponsiveDrawer>
    )
}
