import React, {useEffect} from 'react';
import {Radar} from 'react-chartjs-2';

const data = {
    labels: [],
    datasets: [
        {
            label: 'Joy Rating',
            data: [],
            backgroundColor: 'rgba(91,16,255,0.2)',
            borderColor: 'rgb(91,16,255)',
            borderWidth: 1,
        },
    ],
};

function JoyRatingRadarChart({response, survey}){

    useEffect(() => {
        let scores = {}
        let categoryCounts = {}
        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    if (scores[skill.category] === undefined) {
                        scores[skill.category] = 0
                        categoryCounts[skill.category] = 0
                    }

                    if (skill.isSelected) {
                        scores[skill.category] += skill.joyRating
                        categoryCounts[skill.category] += 1
                    }

                })
            })
        }

        Object.entries(categoryCounts).forEach(([category, count]) => {
          scores[category] = count > 0 ? scores[category] / count : 0
        })
        data.labels = Object.keys(scores)
        data.datasets[0].data = Object.values(scores)

    }, [response])

    const options = {
        scale: {
            ticks: { beginAtZero: true},
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 3,
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Joy Rating',
            },
            legend: {
                display: false
            }
        }
    };

    return (
        <React.Fragment>
            <Radar data={data} options={options} />
        </React.Fragment>
    )
}

export default JoyRatingRadarChart;
