import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SkillpacaApi from "../../services/SkillpacaApi";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import FullScreenLoading from "../util/FullScreenLoading";
import IndividualResponseTable from "./IndividualResponseTable";
import journey from '../../static/undraw_adventure_map_hnin.svg'
import {Typography} from "@material-ui/core";
import {getCurrentGivenName, getCurrentUsername} from "../../services/Util";
import LaunchSurveyButton from "./LaunchSurveyButton";

const skillpacaApi = SkillpacaApi()

export default function IndividualOverviewContainer() {

    const {surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState();
    const [mostRecentCompletedResponse, setMostRecentCompletedResponse] = useState();
    const [responses, setResponses] = useState([])


    useEffect(() => {
        setIsLoading(true)

        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getResponses()]).then(([survey, responses]) => {
            setSurvey(survey)

            let mostRecentCompletedResponse = null

            if (responses && Array.isArray(responses) && responses.length) {
                responses.sort((a, b) => new Date(a.modified) - new Date(b.modified))
                responses.forEach(response => {
                    if (response.status === "COMPLETED" && response.username === getCurrentUsername() && response.targetUsername === getCurrentUsername()) {
                        if (!mostRecentCompletedResponse || response.created > mostRecentCompletedResponse.created) {
                            mostRecentCompletedResponse = response
                        }
                    }
                })
            }

            setResponses(responses)

            setMostRecentCompletedResponse(mostRecentCompletedResponse)
            setIsLoading(false)

        })
    }, [surveyId])

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.overview}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    if (!mostRecentCompletedResponse) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.overview}>
                <div style={{height: '100vh', width: '100%'}}>
                    <div style={{textAlign: 'center', marginTop: 100}}>
                        <img alt={'Journey'} src={journey} style={{marginBottom: 24, maxWidth: '100%'}} height={300}/>
                        <Typography variant={"h5"}>Hello {getCurrentGivenName()}!</Typography>
                        <Typography variant={"subtitle1"} style={{marginBottom: 24}}>Every journey starts with one step.<br/>Discover where you are today so you can figure out where you want to be tomorrow.</Typography>
                        <LaunchSurveyButton surveyId={surveyId}>Let's go</LaunchSurveyButton>
                    </div>
                </div>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.overview} response={mostRecentCompletedResponse}>
            <IndividualResponseTable survey={survey} response={mostRecentCompletedResponse} responses={responses}/>
        </ResponsiveDrawer>
    )
}
