import React from 'react';
import logo from '../../static/skillpaca_logo_full_lockup.svg';
import Grid from "@material-ui/core/Grid";

function Logo({logoUrl}) {
    return (
        <Grid item xs={10} style={{textAlign: "center", paddingTop: "20px", paddingBottom: "20px", margin: "auto"}}>
            <img src={logoUrl ? logoUrl : logo} alt="Logo" style={{width: '100%', height: '100%'}}/>
        </Grid>
    )
}

export default Logo;
