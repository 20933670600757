import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SkillpacaApi from "../../services/SkillpacaApi";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {FormControl, makeStyles, Typography} from "@material-ui/core";
import {getCurrentUsername} from "../../services/Util";

const skillpacaApi = SkillpacaApi()

const useStyles = makeStyles((theme) => ({
    inputs: {
        marginTop: '4px',
    },
    border: {
        border: 'solid 1px rgb(106 106 106)'
    },
    borderHighlight: {
        border: 'solid 2px #5B10FF'
    }
}))

const trackClick = (mentor, skill) => {
    if (window && window.analytics) {
        window.analytics.track('Mentor: Clicked IM INTERESTED', {skillTitle: skill.title, mentor: mentor.username, mentee: getCurrentUsername()});
    }
}

const trackSend = (mentor, skill, goal) => {
    if (window && window.analytics) {
        window.analytics.track('Mentor: Clicked SEND', {skillTitle: skill.title, mentor: mentor.username, goal: goal, mentee: getCurrentUsername()});
    }
}

export default function MenteeGoalDialog({mentor, skill, onSubmitCallback, highlight}) {
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [goal, setGoal] = useState('')

    const classes = useStyles()

    const handleClickOpen = () => {
        setGoal('')
        setIsSubmitting(false)
        setOpen(true);

        trackClick(mentor, skill)
    };

    const handleClose = () => {
        setGoal('')
        setIsSubmitting(false)
        setOpen(false);
    };

    const canSubmit = () => {
        return goal.length > 0
    }

    async function submit() {
        setIsSubmitting(true)
        //SEND DATA TO SEGMENT
        trackSend(mentor, skill, goal)
        onSubmitCallback()
        setOpen(false)
    }

    return (
        <div style={{height: '100%'}}>
            <div onClick={handleClickOpen} className={highlight ? classes.borderHighlight : classes.border} style={{height: '100%', width: '100%', borderRadius: 16, padding: 32, cursor: 'pointer', boxShadow: '#cfcfcf 0px 2px 10px', display: "flex"}}>
                <div style={{margin: "auto", fontSize: 16}}>
                    <strong>{skill.title}</strong>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll="body" fullWidth={true} PaperProps={{style: {overflowY: 'visible'}}}>
                <DialogTitle id="form-dialog-title">How can I help you with {skill.title}?</DialogTitle>
                <DialogContent style={{ overflowY: 'visible' }}>
                    <TextField
                        autoFocus
                        variant={"outlined"}
                        id="goal"
                        multiline={true}
                        minRows={4}
                        fullWidth
                        onChange={(e) => setGoal(e.target.value)}
                        placeholder={"Add a brief sentence of what you're hoping to achieve"}
                        // helperText=""
                        value={goal}
                        className={classes.inputs}
                    />
                </DialogContent>
                <DialogActions>
                    <React.Fragment>
                        <Button onClick={handleClose} disabled={isSubmitting} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={submit} disabled={!canSubmit() || isSubmitting} color="primary">
                            Send
                        </Button>
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        </div>
    );
}
