import jsonwebtoken from "jsonwebtoken";
import jwkToPem from 'jwk-to-pem'

const ID_TOKEN = 'id_token'

// const jwks = {
//     "keys": [
//         {
//             "alg": "RS256",
//             "e": "AQAB",
//             "kid": "yO0i7rU433yKu3UWEImT/mto/1DJQrMLPR4fRZXdar0=",
//             "kty": "RSA",
//             "n": "vEJz9KfSRbZUw299laAyBKxCGCUwe4uOi0FRJmr_6RjZuHJ4OpmVrvAR4ErU82PF7drjRLjYt9e2teA0qkIo7rbaBT0F8iAJoTT4Z1V3tqBG7T9dEXczlGS0n0gmyvs7TCcA3sHwcX1n_K-1oy2hPbcx5zmeX3iXZ4d639VIEbfnL7CKeLga4JxFjrdopwGiZMeM37Zud11QQqyGHa2LlRehxTH94BDOoJd77PW6u5urt_BGdrBp7TpYoRo1JgOKb_xM5mPbQycgl8IqiOWaFtW-RfuDTX2_Nw-Ggpkc1cS1Xw7u0zHef_xBLvv1QrClwhzO9Fz5T2TTa7Mdv50YhQ",
//             "use": "sig"
//         },
//         {
//             "alg": "RS256",
//             "e": "AQAB",
//             "kid": "RXsBK9bR6cO6KfywSYCUXNH1MxiQAzibSrrj8lK3s9g=",
//             "kty": "RSA",
//             "n": "tMYASlXkb01kQR6HBX86Uyeng8HkTyYCyGp4oDQAFAKmNLD96a21QRoFF7lwcurzKJuUKWGtxXCNDaH2FyUH91fV99pusCkiz2ZrIsKjtokEc0V84VvlGVBuN9zqbNC63ArVmezY0ZgkUqpx7-kok18ukwU6wePVc0HML_u88h5JF_uhktwd4ED_YVdOsD_LktUEPoSIOWhChC58GbK81XwjRlNRvBJK1Q4cA24WVXwKv6EJpXgu2NUjDLez_qK2IG8hC4zM9l94IxG0ZJ9Ak7CfSgN6WZEYUITvzxVq5B1Ec0LR34vlSAt5LQSVWlUpmEOS7zL3bWTCtLCcCb00hw",
//             "use": "sig"
//         }
//     ]
// }

const jwks = {
    "keys": [
        {
            "alg": "RS256",
            "e": "AQAB",
            "kid": "0e1Zc2dKSJ+sxyv+zZN75VCilvWiRIA+1l/dit5W5Xg=",
            "kty": "RSA",
            "n": "0DPK9KAC1kZccaxkRRhOuiztgbPrduRKhgU4DD0hnkOMzeWFj6CgLmp4Q6f_UUK9fKSwb1q4J3bPChibHIYNVquP6uDRSmeyybLhcewCqd3Wxmvk613H51Ek0xbp-ot1zlq5v58j9ggV5IUKMWckOonvcN-LmVwdJgEEQRfvvi8lY3wLqeuQbrFHsdj8Tfc1TVrRJzQj47FvLUcIIZdQpZVRH8wHJYiznlxN6k_fCDK7VcW5AcqfI8fSq6tRLLHHIZJDzjiHlhkpfEapfTObyHbGjxaHtp549OVoMFc0x0hGNxdEeh-zbbIBCE7YbWG-jx20i_llz8Y523NhBiIF7w",
            "use": "sig"
        },
        {
            "alg": "RS256",
            "e": "AQAB",
            "kid": "XRPqytpZzcQPdEwyxmKYMNN1aby9xBSKkULkUEbc/pM=",
            "kty": "RSA",
            "n": "0X25EyKTVw-2Gfiu7Grcl-xhMPqsuFOv5yreT4z0b6HzsRZSxFOij5lCdo_SzWJowJPOUataqSp7i8vaJhaI_ioR5GCn17Xa-tzky-MG6iVLG2n-dsFZDcg0WxCFe969G3KW5612mKwVP6l2ZEsApV9jF_bnmQeNhCkjHUkcOl_yMS41ngrfb82ePVzH5vI6lA75zQpiFgevUaIFvar-4MpGHy_YAcx66og3S_q_7kcG-ywatk8PM5ugPCcGQZ9ABc7C3g2yxLA9nVahRe3ao2vcmWhXN-e9zBBNqqS8yhoLmHwsBYkAU3lhFEyglmCLocDbPtezo1WE--idKJlB5Q",
            "use": "sig"
        }
    ]
}

const AuthService = () => {
    const login = async (id_token) => {
        const verified = await verifyToken(id_token)
        if (verified) {
            localStorage.setItem(ID_TOKEN, id_token)
        }
    }

    const getJwt = () => {
        return localStorage.getItem(ID_TOKEN)
    }

    const getDecodedJwt = () => {
        return jsonwebtoken.decode(getJwt(), {complete: true});
    }

    const getCurrentTenant = () => {
        return getDecodedJwt().payload["custom:tenant_id"]
    }

    const logout = () => {
        localStorage.removeItem(ID_TOKEN)
    }

    const isLoggedIn = async () => {
        const jwt = getJwt()
        if (!jwt) {
            return false
        }
        return verifyToken(jwt)
    }

    const verifyToken = async (jwt) => {
        const decodedToken = jsonwebtoken.decode(jwt, {complete: true})
        const kid = decodedToken.header.kid
        let jwk = null;
        jwks.keys.forEach(key => {
            if (key.kid === kid) {
                jwk = key
            }
        })

        if (!jwk) {
            localStorage.removeItem(ID_TOKEN)
            throw new Error('Could not find jwk with "kid" ' + kid)
        }

        const pem = jwkToPem(jwk);
        return new Promise((resolve, reject) => {
            jsonwebtoken.verify(jwt, pem, { algorithms: ['RS256'] }, function(err, decodedToken) {
                if (err) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            });
        })
    }

    return {
        login,
        getJwt,
        getDecodedJwt,
        getCurrentTenant,
        logout,
        isLoggedIn,
        verifyToken
    }

}

export default AuthService
