import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Logo from "./util/Logo";
import LogoutButton from "./util/LogoutButton";
import {useNavigate} from "react-router-dom";
import PieChartIcon from '@material-ui/icons/PieChart';
import {ListSubheader} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthService from "../services/AuthService";
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import GroupIcon from '@material-ui/icons/Group';

const authService = AuthService()
const reload = () => {
    window.location.reload();
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: '#f5f5f5',
        color: '#000000'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: '#f5f5f5',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}));

export const ListItems = {
    overview: 'Overview',
    learningLibrary: 'Team Library',
    peerLearning: 'Peer Learning'
}

const track = (skill) => {
    if (window && window.analytics) {
        window.analytics.track("Clicked target skill", {"skillTitle": skill});
    }
}

function ResponsiveDrawer({window, children, surveyId, activeItem, targetSkills, response}) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigateToUrl = (basePath) => {
        if (surveyId) {
            navigate('/' + basePath + '/' + surveyId)
        }
    }

    const renderTargetSkills = () => {
        let renderedSkills = [];
        let targetSkills = [];
        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    if (skill.isLearn) {
                        targetSkills.push({...skill, id: skillId})
                    }
                })
            })

            if (targetSkills && Array.isArray(targetSkills)) {
                renderedSkills = targetSkills.map(targetSkill => {
                    return (
                        <ListItem key={targetSkill.id} button onClick={() => track(targetSkill.title)}>
                            <ListItemText primary={targetSkill.title}/>
                        </ListItem>
                    )
                })
            }
        }

        if (renderedSkills && renderedSkills.length) {
            return (
                <React.Fragment>
                    <Divider/>
                    <ListSubheader>Your Focus Skills</ListSubheader>
                    {renderedSkills}
                </React.Fragment>
            )
        }

        return ''

    }

    const renderPeerLearning = () => {
        if (authService.getCurrentTenant() === "practeraEng" || authService.getCurrentTenant() === "pageup") {
            return (
                <ListItem button onClick={() => navigateToUrl('peer-learning')}>
                    <ListItemIcon><GroupIcon color={activeItem === ListItems.peerLearning ? 'primary' : 'inherit'} /></ListItemIcon>
                    <ListItemText className={activeItem === ListItems.peerLearning ? classes.selected : ''} primary={ListItems.peerLearning} />
                </ListItem>
            )
        }
        return ''
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} style={{margin: 'auto'}} ><Logo/></div>
            <List>
                <ListItem button onClick={() => navigateToUrl('overview')}>
                    <ListItemIcon><PieChartIcon color={activeItem === ListItems.overview ? 'primary' : ''} /></ListItemIcon>
                    <ListItemText className={activeItem === ListItems.overview ? classes.selected : ''} primary={ListItems.overview} />
                </ListItem>
                {/*{renderPeerLearning()}*/}
                {/*<ListItem button onClick={() => navigateToUrl('learning-library')}>*/}
                {/*    <ListItemIcon><LocalLibraryIcon color={activeItem === ListItems.learningLibrary ? 'primary' : ''} /></ListItemIcon>*/}
                {/*    <ListItemText className={activeItem === ListItems.learningLibrary ? classes.selected : ''} primary={ListItems.learningLibrary} />*/}
                {/*</ListItem>*/}
                {renderTargetSkills()}

            </List>
            <List style={{position: 'absolute', bottom: 0, width: '100%'}}>
                {/*<Divider/>*/}
                <ListSubheader>Operations</ListSubheader>
                <ListItem button onClick={() => {
                    authService.logout()
                    reload()
                }}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap className={classes.title}>
                            {activeItem}
                        </Typography>
                        <LogoutButton/>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <Hidden smUp implementation="js">
                    <div className={classes.toolbar} />
                    {children}
                </Hidden>
                <Hidden xsDown implementation="js">
                    {children}
                </Hidden>
            </main>
        </div>
    );
}

export default ResponsiveDrawer;
