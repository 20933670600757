import {useNavigate} from "react-router-dom";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import React, {useCallback, useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from "@material-ui/core/Grid";
import {Hidden, Typography} from "@material-ui/core";
import SkillsSelect from "../util/SkillsSelect";
import SelfRatingRadarChart from "./SelfRatingRadarChart";
import {getCurrentGivenName, getCurrentUsername} from "../../services/Util";
import JoyRatingRadarChart from "./JoyRatingRadarChart";
import AuthService from "../../services/AuthService";
import LaunchSurveyButton from "./LaunchSurveyButton";
import SelfRatingGrowthChart from "./SelfRatingGrowthChart";
import {formatDistance} from "date-fns";

const columns = [
    { field: 'category', headerName: 'Category', flex: 1 },
    { field: 'title', headerName: 'Skill', flex: 1 },
    { field: 'isSelected', headerName: 'Has the skill', type: 'boolean', flex: 0.6, renderCell: (params) => renderBoolean(params.value) },
    { field: 'selfRating', headerName: 'Self Rating', type: 'number', flex: 0.6, renderCell: (params) => renderSelfRating(params) },
    { field: 'joyRating', headerName: 'Joy Rating', type: 'number', flex: 0.6, renderCell: (params) => renderEmoji(params) },
    { field: 'isLearn', headerName: 'Interested in Learning', type: 'boolean', flex: 1, renderCell: (params) => renderBoolean(params.value)}
];

function renderBoolean(value) {
    return value ? <CheckCircleIcon style={{color: 'green'}}/>: <CancelIcon style={{color: 'red'}}/>
}

function renderEmoji(params) {

    let emoji = ''
    switch (params.value) {
        case 1:
            emoji = <span>😫</span>
            break;
        case 2:
            emoji = <span>🙂</span>
            break;
        case 3:
            emoji = <span>😍</span>
            break;
        default:
            emoji = <span/>
    }

    if(params.row.previousJoyRating !== null) {
        const score1 = params.value
        const score2 = params.row.previousJoyRating
        if (score1 > score2) {
            return <span>{emoji}<span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {score1 - score2}</span></span>
        } else if (score2 > score1) {
            return <span>{emoji}<span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {score2 - score1}</span></span>
        } else {
            return <span>{emoji}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
        }
    }
    return <span>{emoji}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
}

function renderSelfRating(params) {
    if(params.row.previousSelfRating !== null) {
        const score1 = params.value
        const score2 = params.row.previousSelfRating
        if (score1 > score2) {
            return <span>{score1}<span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {score1 - score2}</span></span>
        } else if (score2 > score1) {
            return <span>{score1}<span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {score2 - score1}</span></span>
        } else {
            return <span>{score1}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
        }
    } else {
        return params.value
    }
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const authService = AuthService()

export default function IndividualResponseTable({survey, responses}) {
    // const {responseId} = useParams()
    const navigate = useNavigate()

    const [rows, setRows] = useState([])
    const [response, setResponse] = useState()
    const [selfResponses, setSelfResponses] = useState([])
    const [reviewerResponses, setReviewerResponses] = useState([])
    const [selection, setSelection] = useState([])
    const [filterSkills, setFilterSkills] = useState([])

    const updateRows = useCallback(((individualsResponse, previousResponse) => {
        const rows = []

        // const columns = columnsInitial
        // columns[3].hide = true

        if (individualsResponse && individualsResponse.skillGroups) {
            Object.entries(individualsResponse.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    let rowData = {}

                    if (!filterSkills.length || filterSkills.includes(skillId)) {
                        rowData = {
                            id: skillId,
                            category: skill.category,
                            title: skill.title,
                            isSelected: skill.isSelected,
                            selfRating: skill.isSelected ? skill.selfRating : null,
                            joyRating: skill.isSelected ? skill.joyRating : null,
                            isLearn: skill.isLearn
                        }

                        if (previousResponse && previousResponse.skillGroups && previousResponse.skillGroups[skillGroupId] && previousResponse.skillGroups[skillGroupId][skillId]) {
                            rowData.previousSelfRating = previousResponse.skillGroups[skillGroupId][skillId].selfRating
                            rowData.previousJoyRating = previousResponse.skillGroups[skillGroupId][skillId].joyRating
                        } else {
                            rowData.previousSelfRating = null
                            rowData.previousJoyRating = null
                        }

                        // if (reviewerResponses && Array.isArray(reviewerResponses) && reviewerResponses.length) {
                        //     let reviewerRatingTotal = 0
                        //     let reviewerRatingCount = 0
                        //     reviewerResponses.forEach(response => {
                        //         reviewerRatingCount++
                        //         columns[3].hide = false
                        //         if (response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId] && response.skillGroups[skillGroupId][skillId].selfRating) {
                        //             reviewerRatingTotal += response.skillGroups[skillGroupId][skillId].selfRating
                        //         }
                        //
                        //     })
                        //
                        //     rowData.yourRating = reviewerRatingCount ? reviewerRatingTotal / reviewerRatingCount : 0
                        // }

                        rows.push(rowData)
                    }

                })
            })
        }
        // setColumns(columns)
        setRows(rows)
    }), [filterSkills])

    useEffect(() => {
        let individualsResponse = null
        const reviewerResponsesLocal = []
        const selfResponsesLocal = []

        const username = getCurrentUsername()

        if (responses && Array.isArray(responses)) {
            responses.forEach(response => {
                if (response.status === "COMPLETED" && response.username === username && response.targetUsername === username) {
                    selfResponsesLocal.push(response)
                }
            })

            if (individualsResponse) {
                responses.forEach(response => {
                    if (response.targetUsername && response.targetUsername === individualsResponse.username && response.username !== individualsResponse.username) {
                        if (response.status === "COMPLETED") {
                            reviewerResponsesLocal.push(response)
                        }
                    }
                })
            }
        }

        setResponse(selfResponsesLocal[(selfResponsesLocal.length - 1)])
        setSelfResponses(selfResponsesLocal)
        setReviewerResponses(reviewerResponsesLocal)

    }, [survey, responses])

    useEffect(() => {
        const previousResponse = selfResponses.length > 1 ? selfResponses[selfResponses.length-2] : null
        updateRows(response, previousResponse)
    }, [updateRows, filterSkills, response, selfResponses])

    function handleSkillsSelectChange(values) {
        setFilterSkills(values.map(value => value.value))
    }

    if (!response) {
        return <div></div>
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={'h5'}>Welcome, {getCurrentGivenName()}! 👋</Typography>
                {response && response.modified ? <Typography variant={"subtitle1"}>Last Check-in: {formatDistance(new Date(response.modified), new Date(), { addSuffix: true })}</Typography> : ''}
            </Grid>
            <Hidden smDown><Grid item md={1}/></Hidden>
            <Grid item xs={12} md={4}>
                <SelfRatingGrowthChart selfResponses={selfResponses}/>
            </Grid>
            <Hidden smDown><Grid item md={2}/></Hidden>
            <Grid item xs={12} md={4}>
                <SelfRatingRadarChart response={response} survey={survey} />
            </Grid>
            {/*<Grid item xs={0} md={4} style={{padding: 24}}>*/}
            {/*</Grid>*/}
            <Grid item xs={2} md={8} style={{marginBottom: 16, marginTop: 24}}>
                <LaunchSurveyButton surveyId={survey.id}>Update your answers</LaunchSurveyButton>
            </Grid>
            <Grid item xs={10} md={4} style={{marginBottom: 16, marginTop: 24}}>
                <SkillsSelect survey={survey} onChange={handleSkillsSelectChange} isMulti={true}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                    <DataGrid autoHeight
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        onSelectionModelChange={(newSelection) => {
                            setSelection(newSelection.selectionModel);
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    )
}
