import DescriptionIcon from '@material-ui/icons/Description';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HeadsetIcon from '@material-ui/icons/Headset';
import PeopleIcon from '@material-ui/icons/People';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import LanguageIcon from '@material-ui/icons/Language';
import SchoolIcon from '@material-ui/icons/School';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const style = {
    verticalAlign: 'bottom',
    display: 'inline-block',
    marginRight: 4
}

const TYPES = {
    ARTICLE: 'ARTICLE',
    BOOK: 'BOOK',
    PODCAST: 'PODCAST',
    SOCIAL: 'SOCIAL',
    VIDEO: 'VIDEO',
    WEBSITE: 'WEBSITE',
    COURSE: 'COURSE',
    OTHER: 'OTHER'
}

export const options = [
    {
        value: TYPES.ARTICLE,
        label: getJsx(TYPES.ARTICLE)
    },
    {
        value: TYPES.BOOK,
        label: getJsx(TYPES.BOOK)
    },
    {
        value: TYPES.PODCAST,
        label: getJsx(TYPES.PODCAST)
    },
    {
        value: TYPES.SOCIAL,
        label: getJsx(TYPES.SOCIAL)
    },
    {
        value: TYPES.VIDEO,
        label: getJsx(TYPES.VIDEO)
    },
    {
        value: TYPES.WEBSITE,
        label: getJsx(TYPES.WEBSITE)
    },
    {
        value: TYPES.COURSE,
        label: getJsx(TYPES.COURSE)
    },
    {
        value: TYPES.OTHER,
        label: getJsx(TYPES.OTHER)
    }
]

export function getJsx(type) {
    switch (type) {
        case TYPES.ARTICLE:
            return <span><DescriptionIcon style={style}/>&nbsp;Article</span>
        case TYPES.BOOK:
            return <span><MenuBookIcon style={style}/>&nbsp;Book</span>
        case TYPES.PODCAST:
            return <span><HeadsetIcon style={style}/>&nbsp;Podcast</span>
        case TYPES.SOCIAL:
            return <span><PeopleIcon style={style}/>&nbsp;Social</span>
        case TYPES.VIDEO:
            return <span><VideoLibraryIcon style={style}/>&nbsp;Video</span>
        case TYPES.WEBSITE:
            return <span><LanguageIcon style={style}/>&nbsp;Website</span>
        case TYPES.COURSE:
            return <span><SchoolIcon style={style}/>&nbsp;Course</span>
        case TYPES.OTHER:
            return <span><EmojiObjectsIcon style={style}/>&nbsp;Other</span>
    }
}
