import {useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Button, Hidden, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import MenteeGoalDialog from "./MenteeGoalDialog";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";

export default function MentorDetails({mentors, response}) {
    const {username} = useParams()
    const [mentor, setMentor] = useState({})
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (mentors) {
            mentors.forEach(mentor => {
                if (mentor.username === username) {
                    setMentor(mentor)
                }
            })
        }
    }, [mentors, username, setMentor])

    function onGoalSubmitted() {
        setOpen(true)
    }

    function renderSkills(skills, response) {

        const targetSkills = []
        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    if (skill.isLearn) {
                        targetSkills.push(skill.title)
                    }
                })
            })
        }

        const skillsJsx = skills.map(skill => {
            return (
                <Grid key={skill.id} item xs={12} md={4}>
                    <MenteeGoalDialog skill={skill} mentor={mentor} onSubmitCallback={onGoalSubmitted} highlight={targetSkills.includes(skill.title)}/>
                </Grid>
            )
        })

        return (
            <Grid container spacing={3} style={{marginTop: 16}}>
                {skillsJsx}
            </Grid>
        )

    }


    if (mentor && mentor.username) {
    return (
            <React.Fragment>
                <div style={{height: 120, margin: -24, background: '#5B10FF'}}/>

                <Grid container justifyContent={"center"}>
                    <Hidden smDown><Grid item xs={4} /></Hidden>
                    <Grid item xs={12} md={4}>
                        <img src={mentor.picture} style={{marginTop: -24, height: 139, width: 139, borderRadius: 60, objectFit: "cover", borderStyle: 'solid', borderWidth: 5, borderColor: 'white'}} alt={"Profile"}/>
                    </Grid>
                    <Hidden smDown><Grid item xs={4} /></Hidden>
                    <Hidden smDown><Grid item xs={4} /></Hidden>
                    <Grid item xs={12} md={4}>
                        <Typography variant={"h5"}>Hi, I'm {mentor.name} 👋</Typography>
                        <Typography variant={"subtitle2"}>{mentor.role}</Typography>
                        <Typography variant={"body1"} style={{marginTop: 16}} dangerouslySetInnerHTML={{__html:mentor.bio}}/>
                        {/*<Typography variant={"body1"} style={{marginTop: 16}}><strong>How you can reach out to me</strong></Typography>*/}
                        {/*<Typography variant={"body1"} >{mentor.howToReach}</Typography>*/}
                        <Typography variant={"h5"} style={{marginTop: 48}}>Select the skill you would like help with</Typography>
                    </Grid>
                    <Hidden smDown><Grid item xs={4} /></Hidden>
                    <Hidden smDown><Grid item xs={2} /></Hidden>
                    {/*<MessageSentSuccessfully/>*/}
                    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Your request has been received! 🎉
                        </Alert>
                    </Snackbar>
                    <Grid item xs={10} md={8}>
                        {renderSkills(mentor.skills, response)}
                    </Grid>
                    <Hidden smDown><Grid item xs={2} /></Hidden>
                </Grid>
            </React.Fragment>
        )
    } else {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={"body1"}>Could not find the mentor with the username: {username}</Typography>
                </Grid>
            </Grid>
        )
    }

}
