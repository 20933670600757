import {Route, Routes} from "react-router-dom";
import Callback from "./Callback";
import PreLogin from "./PreLogin";


export default function LoginContainer() {
    return (
        <Routes>
            <Route path="/callback" element={<Callback />} />
            <Route path="/*" element={<PreLogin/>} />
        </Routes>
    )
}
