import React, {useEffect, useState} from "react";
import SkillpacaApi from "../services/SkillpacaApi";
import FullScreenLoading from "./util/FullScreenLoading";
import {useNavigate} from "react-router-dom";
import LogoutButton from "./util/LogoutButton";

const skillpacaApi = SkillpacaApi();

export default function Home() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        skillpacaApi.getResponses().then(responses => {
            if (responses && Array.isArray(responses) && responses.length > 0) {
                navigate('/overview/' + responses[0].surveyId)
            } else {
                skillpacaApi.getSurveys().then(surveys => {
                    skillpacaApi.createResponse(surveys[0].id).then(response => {
                        navigate('/overview/' + response.surveyId)
                    })
                })
                // setIsLoading(false)
            }
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
        })
    }, [navigate])

    if (isLoading) {
        return (
            <FullScreenLoading/>
        )
    }

    return (
        <div>
            <p>Could not find any surveys in your tenant</p>
            <LogoutButton/>
        </div>
    )
}
