import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import React, {useEffect, useState} from "react";
import SkillpacaApi from "../../services/SkillpacaApi";
import {makeStyles} from "@material-ui/core/styles";
import {getCurrentUsername} from "../../services/Util";

const skillpacaApi = SkillpacaApi()

const useStyles = makeStyles((theme) => ({
    upArrowActive: {
        verticalAlign: 'bottom',
        display: 'inline-block',
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    upArrow: {
        verticalAlign: 'bottom',
        display: 'inline-block',
        cursor: 'pointer'
    },
    downArrowActive: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    downArrow: {
        cursor: 'pointer'
    }
}))

export default function Vote({learningLibraryItem}) {

    const classes = useStyles()

    const [upvotes, setUpvotes] = useState([])
    const [downvotes, setDownvotes] = useState([])

    useEffect(() => {
        setUpvotes(learningLibraryItem.upvotes)
        setDownvotes(learningLibraryItem.downvotes)
    }, [learningLibraryItem])

    async function upvote() {
        skillpacaApi.voteLearningLibraryItem(learningLibraryItem.id, {type: 'UPVOTE'})
        const username = getCurrentUsername()
        const downvotesLocal = downvotes.filter(voteUsername => voteUsername !== username)
        const upvotesLocal = upvotes.filter(voteUsername => voteUsername !== username)
        upvotesLocal.push(username)
        setDownvotes(downvotesLocal)
        setUpvotes(upvotesLocal)
    }

    async function downvote() {
        skillpacaApi.voteLearningLibraryItem(learningLibraryItem.id, {type: 'DOWNVOTE'})
        const username = getCurrentUsername()
        const downvotesLocal = downvotes.filter(voteUsername => voteUsername !== username)
        const upvotesLocal = upvotes.filter(voteUsername => voteUsername !== username)
        downvotesLocal.push(username)
        setDownvotes(downvotesLocal)
        setUpvotes(upvotesLocal)
    }

    async function removeVote() {
        skillpacaApi.voteLearningLibraryItem(learningLibraryItem.id, {type: null})
        const username = getCurrentUsername()
        const downvotesLocal = downvotes.filter(voteUsername => voteUsername !== username)
        const upvotesLocal = upvotes.filter(voteUsername => voteUsername !== username)
        setDownvotes(downvotesLocal)
        setUpvotes(upvotesLocal)
    }

    function renderUpArrow() {
        if (upvotes.includes(getCurrentUsername())) {
            return <ThumbUpIcon className={classes.upArrowActive} onClick={removeVote}/>
        } else {
            return <ThumbUpIcon className={classes.upArrow} onClick={upvote}/>
        }
    }

    function renderDownArrow() {
        if (downvotes.includes(getCurrentUsername())) {
            return <ThumbDownIcon className={classes.downArrowActive} onClick={removeVote}/>
        } else {
            return <ThumbDownIcon className={classes.downArrow} onClick={downvote}/>
        }
    }

    return (
        <React.Fragment>
            {renderUpArrow()}
            <span style={{display: 'block', fontSize: '1.1rem', margin: 7}}>{upvotes.length - downvotes.length}</span>
            {renderDownArrow()}
        </React.Fragment>
    )
}
