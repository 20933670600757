import AuthService from "../../services/AuthService";
import {Button} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";

const authService = AuthService()
const reload = () => {
    window.location.reload();
}

export default function LogoutButton() {

    const logout = () => {
        authService.logout()
        reload()
    }

    return <Button color="inherit" onClick={logout}>Logout <ExitToAppIcon/></Button>
}
