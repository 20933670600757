import {useEffect} from "react";
import FullScreenLoading from "../util/FullScreenLoading";
import {useLocation} from "react-router-dom";

const redirect = window.location.origin + '/callback'
const LOGIN_URL = 'https://login.skillpaca.com/login?client_id=7nfbdodr9mbtm75ifrf7hleo3t&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+redirect

export default function PreLogin() {

    const location = useLocation()

    useEffect(() => {
        localStorage.setItem('success_login_path', location.pathname)
        window.location = LOGIN_URL;
    }, [location.pathname])

    return (
        <FullScreenLoading/>
    )
}
