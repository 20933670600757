import {useEffect, useState} from "react";
import Select from 'react-select';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function SkillsSelect({survey, onChange, isMulti=false}) {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const options = []
        if (survey && survey.skillGroups) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                const groupedOptions = [];
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    groupedOptions.push({value: skillId, label: skill.title})
                })
                options.push({
                    label: survey.skillGroupsMeta[skillGroupId].category,
                    options: groupedOptions
                })
            })
        }
        setOptions(options)
    }, [survey])

    return (
        <Select
            defaultValue={[]}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            placeholder={"Select skills"}
            formatGroupLabel={formatGroupLabel}
            closeMenuOnSelect={!isMulti}
        />
    )

}
