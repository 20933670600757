import AuthService from "./AuthService";

const authService = AuthService()

export const getCurrentUsername = () => {
    return authService.getDecodedJwt().payload["cognito:username"]
}

export const getCurrentGivenName = () => {
    return authService.getDecodedJwt().payload["given_name"]
}

export const getRubric = (survey) => {
    if (survey.rubric && Array.isArray(survey.rubric)) {
        return survey.rubric.map(rubricLevel => rubricLevel.label)
    } else if (survey.skillGroups) {
        let rubric;
        Object.values(survey.skillGroups).forEach(skillGroup => {
            Object.values(skillGroup).forEach(skill => {
                if (skill.rubric && Array.isArray(skill.rubric)) {
                    rubric = skill.rubric.map(rubricLevel => rubricLevel.label)
                }
            })
        });
        if (rubric) {
            return rubric
        }
    }
    return [1, 2, 3, 4, 5, 6, 7, 8]
}
