import React, {useEffect, useState} from 'react';
import {Radar} from 'react-chartjs-2';
import {getRubric} from "../../services/Util";

function SelfRatingRadarChart({response, survey}){

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Self Rating',
                data: [],
                backgroundColor: 'rgba(91,16,255,0.2)',
                borderColor: 'rgb(91,16,255)',
                borderWidth: 1,
            },
        ],
    })

    useEffect(() => {
        let scores = {}
        let categoryCounts = {}
        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    if (scores[skill.category] === undefined) {
                        scores[skill.category] = skill.selfRating
                        categoryCounts[skill.category] = 1
                    } else {
                        scores[skill.category] += skill.selfRating
                        categoryCounts[skill.category] += 1
                    }
                })
            })
        }

        Object.entries(categoryCounts).forEach(([category, count]) => {
          scores[category] = scores[category] / count
        })
        data.labels = Object.keys(scores)
        data.datasets[0].data = Object.values(scores)

        setData({...data})

    }, [response])

    const options = {
        scale: {
            ticks: { beginAtZero: true},
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: getRubric(survey).length,
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Self Rating',
            },
            // legend: {
            //     display: false
            // }
        },
        aspectRatio: 1.2
    };

    return (
        <React.Fragment>
            <Radar data={data} options={options} />
        </React.Fragment>
    )
}

export default SelfRatingRadarChart;
