import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Chip, Grid, Typography} from "@material-ui/core";
import teamwork from "../../static/undraw_teamwork_hpdk.svg";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export default function MentorList({mentors, response}) {

    function renderMentorsSkills(skills, response) {

        let skillsJsx = ''
        const targetSkills = []

        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    if (skill.isLearn) {
                        targetSkills.push(skill.title)
                    }
                })
            })
        }

        if (skills && skills.length) {
            skillsJsx = skills.map((skill, index) => {
                if (targetSkills.includes(skill.title)) {
                    if (index < (skills.length - 1)) {
                        return <span key={skill.id}><strong>{skill.title}<CheckCircleIcon style={{verticalAlign: 'text-bottom', height: '0.85em'}}/></strong>, &nbsp;</span>
                    }
                    return <span key={skill.id}><strong>{skill.title}<CheckCircleIcon style={{verticalAlign: 'text-bottom', height: '0.85em'}}/></strong></span>
                } else {
                    if (index < (skills.length - 1)) {
                        return <span key={skill.id}>{skill.title}, &nbsp;</span>
                    }
                    return <span key={skill.id}>{skill.title}</span>
                }
            })
        }

        return (
            <div style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                <p style={{textOverflow: 'ellipsis', overflow: 'hidden'}}><span style={{verticalAlign: 'top', paddingRight: 8}}><ChatBubbleOutlineIcon/></span>
                    {skillsJsx}
                </p>
            </div>
        )
    }

    function renderMentors() {
        let mentorJsx = []
        let isGoodMatch = false

        let targetSkills = []
        if (response && response.skillGroups) {
            Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    if (skill.isLearn) {
                        targetSkills.push(skill.title)
                    }
                })
            })
        }

        if (mentors && Array.isArray(mentors)) {
            mentorJsx = mentors.map(mentor => {

                if (mentor.skills && Array.isArray(mentor.skills))
                mentor.skills.forEach(skill => {
                    if (targetSkills.includes(skill.title)) {
                        isGoodMatch = true
                    }
                })

                return (
                    <Grid key={mentor.username} style={{height: 407}} item xs={12} sm={12} md={6} lg={3}>
                        <Link to={encodeURIComponent(mentor.username)}>
                            <div style={{color: 'white', position: "relative", height: '100%', width: '100%'}}>
                                <div style={{height: '100%', width: '100%', borderRadius: 16, background: 'url("' + mentor.picture + '")', backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                                </div>
                                <div style={{position: 'absolute', bottom: 0, padding: '20px', paddingTop: '50px', paddingBottom: 0, width: '100%', borderRadius: 16, backgroundImage: 'linear-gradient(transparent 0%, rgba(0, 0, 0, 0.87))'}}>
                                    <Typography variant="h5">{mentor.name}</Typography>
                                    <Typography variant="subtitle2"><span style={{paddingRight: 8}}><WorkOutlineIcon style={{verticalAlign: 'bottom'}}/></span>{mentor.role}</Typography>
                                    <Grid container style={{paddingTop: 12}}>
                                        {renderMentorsSkills(mentor.skills, response)}
                                    </Grid>
                                </div>
                                {/*<span style={{position: 'absolute',top: 4, right: 16}}>Good match <HowToRegIcon style={{height: '2.5em', width: '1.5em'}}/></span>*/}
                                {isGoodMatch ? <Chip style={{position: 'absolute', top: 16, right: 16}} color={"primary"} label="Good match!"/> : ''}
                            </div>
                        </Link>
                    </Grid>
                )
            })
        }

        return mentorJsx
    }


    const renderedMentors = renderMentors()

    if (renderedMentors.length > 0 ) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">Select your mentor</Typography>
                    <Typography variant="subtitle1" style={{paddingBottom: 20}}>The following people have volunteered to mentor you on and help you develop key skills!</Typography>
                    <Grid container spacing={3}>
                        {renderedMentors}
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container>
                <div style={{height: '100vh', width: '100%'}}>
                    <div style={{textAlign: 'center', marginTop: 100}}>
                        <img src={teamwork} style={{marginBottom: 24, maxWidth: '100%'}} height={300} alt={"Teamwork"}/>
                        <Typography variant={"h5"}>Learn together!</Typography>
                        <Typography variant={"subtitle1"} style={{marginBottom: 24}}>Soon this page will be filled with some of your colleagues who are more than happy to help you learn!</Typography>
                        {/*<AddLearningLibraryItemButton survey={survey} onSubmitCallback={refreshLearningItems}/>*/}
                    </div>
                </div>
            </Grid>
        )
    }
}
